import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import CreateParcel from "./pages/createParcel";
import Parcel from "./pages/Parcel";
import TrackParcel from "./pages/TrackParcel";
import ParcelList from "./pages/ParcelList";
import ManageParcel from "./pages/ManageParcel";
import UserDashboard from "./pages/UserDashboard";
import ExpandedParcel from "./pages/ExpandedParcel";
import useInactivity from "./components/useInactivity";

function App() {
  // Use the inactivity hook to clear local storage and redirect to login if inactive for 30 minutes
  useInactivity(1800000);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={(() => {
              if (window.location.pathname === "/") {
                window.location.replace("https://e.bloomcargo.com/");
              }
              return null;
            })()}
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/createparcel" element={<CreateParcel />} />
          <Route path="/parcel" element={<Parcel />} />
          <Route path="/track" element={<TrackParcel />} />
          <Route path="/parcellist" element={<ParcelList />} />
          <Route path="/manage" element={<ManageParcel />} />
          <Route path="/userdashboard" element={<UserDashboard />} />
          <Route path="/expanded" element={<ExpandedParcel />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
