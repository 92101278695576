import { useEffect, useState } from "react";
import MessageAlert from "../components/MessageAlert";
import Loader from "../components/Loader";
import useParcelStore from "../stores/parcelStore";
import { useNavigate } from "react-router";
import Navbar from "../components/Navbar";

const TrackParcel = () => {
  const navigate = useNavigate();
  const [trackingCode, setTrackingCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageID, setMessageID] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const setParcel = useParcelStore((state) => state.setParcel);

  // Handle Sign Up
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`https://e-api.bloomcargo.com/api/parcels/track`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ trackingCode }),
      });

      const result = await response.json();

      if (result.parcel) {
        setParcel(result.parcel);
        setMessage("Parcel fetched Successful!");
        setMessageID("successAlert");
        setShowMessage(true);
        setIsLoading(false);

        // console.log(result.parcel.shippingHistory);

        navigate("/parcel");
      } else {
        throw new Error("Invalid tracking code");
      }
    } catch (err) {
      setMessage(err.message);
      setMessageID("declineAlert");
      setShowMessage(true);
      setIsLoading(false);
    }
  };

  // Hide message after 3 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  return (
    <>
      <Navbar />
      {showMessage ? <MessageAlert data={{ message, messageID }} /> : ""}

      <div class="container mx-auto max-w-[800px] flex flex-wrap mt-10">
        <div class="w-full h-[400px]  p-4 bg-white trackingContainer">
          <div className="flex justify-between items-center">
            <h1
              class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-white"
              style={{ fontSize: "30px" }}
            >
              Track Parcel
            </h1>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              id="containerIcon"
            >
              <path d="M62.87,42.38h.07a1.18,1.18,0,0,0,1.17-1.1c0-.22,0-.45,0-.68a11.63,11.63,0,0,0-7.22-10.78l-.85-.34c-1.32-.5-1.72-.71-1.81-1.17a1.17,1.17,0,1,0-2.29.45c.35,1.8,1.9,2.39,3.27,2.91.26.09.52.19.79.31a9.3,9.3,0,0,1,5.77,8.62c0,.18,0,.36,0,.54a1.17,1.17,0,0,0,1.1,1.24Z"></path>
              <path d="M42.38,36.77a6.67,6.67,0,0,1,1.24,1,7.87,7.87,0,0,1,1.59,3.31,7.62,7.62,0,0,0,.3.89,6.18,6.18,0,1,0,9-7.73c-.08,0-.21-.13-.39-.22-1.75-1-4.08-2.56-4.11-5.51a1.16,1.16,0,0,0-1.16-1.16h0a1.17,1.17,0,0,0-1.16,1.18c0,3.1,1.82,5.63,5.33,7.54l.21.12A3.85,3.85,0,1,1,47.63,41c0-.08-.11-.34-.17-.56a10,10,0,0,0-2.12-4.25,8.46,8.46,0,0,0-1.71-1.39,2.45,2.45,0,0,0-3,.19A2.52,2.52,0,0,0,40,38a6.45,6.45,0,0,1,.8,2.64A11.75,11.75,0,0,0,52.47,52.26a11.56,11.56,0,0,0,6.83-2.21,1.17,1.17,0,1,0-1.37-1.89,9.28,9.28,0,0,1-5.46,1.76,9.43,9.43,0,0,1-9.32-9.35,8.69,8.69,0,0,0-1-3.62.19.19,0,0,1,.06-.21s.06-.06.21,0Z"></path>
              <path d="M64.48,8.16,58.25,2.65a4.71,4.71,0,0,0-2.82-1.07h-8a4.69,4.69,0,0,0-2.82,1.07L38.35,8.16a3,3,0,0,0-.78,3.16l4.84,12.61a3,3,0,0,0,2.67,1.84H57.74a3,3,0,0,0,2.68-1.84l4.84-12.61a3,3,0,0,0-.78-3.16Zm-1.4,2.32L58.24,23.09a.71.71,0,0,1-.5.34H45.09a.71.71,0,0,1-.5-.34L39.75,10.48a.72.72,0,0,1,.14-.57l6.24-5.52a2.53,2.53,0,0,1,1.27-.48h8a2.46,2.46,0,0,1,1.27.49l6.24,5.51A.72.72,0,0,1,63.08,10.48Z"></path>
              <path d="M51.41,9.2a4.39,4.39,0,1,0,4.4,4.39A4.4,4.4,0,0,0,51.41,9.2Zm0,6.45a2.06,2.06,0,1,1,2.06-2.06A2.06,2.06,0,0,1,51.41,15.65Z"></path>
              <path d="M87.24,62.39h-4a1.17,1.17,0,0,0,0,2.34h2.87V97.24H16.76V64.73h2.71a1.17,1.17,0,0,0,0-2.34H15.59a1.17,1.17,0,0,0-1.17,1.17V98.41a1.17,1.17,0,0,0,1.17,1.17H87.24a1.17,1.17,0,0,0,1.17-1.17V63.56A1.17,1.17,0,0,0,87.24,62.39Z"></path>
              <path d="M80,66a1.17,1.17,0,0,0-1.17-1.17H23.89a1.17,1.17,0,1,0,0,2.34H78.78A1.17,1.17,0,0,0,80,66Z"></path>
              <path d="M26.26,72.41V93.26a1.17,1.17,0,0,0,2.34,0V72.41a1.17,1.17,0,0,0-2.34,0Z"></path>
              <path d="M35.85,72.41V93.26a1.17,1.17,0,0,0,2.34,0V72.41a1.17,1.17,0,1,0-2.34,0Z"></path>
              <path d="M45.45,72.41V93.26a1.17,1.17,0,0,0,2.34,0V72.41a1.17,1.17,0,1,0-2.34,0Z"></path>
              <path d="M55,72.41V93.26a1.17,1.17,0,0,0,2.34,0V72.41a1.17,1.17,0,0,0-2.34,0Z"></path>
              <path d="M64.64,72.41V93.26a1.17,1.17,0,1,0,2.33,0V72.41a1.17,1.17,0,1,0-2.33,0Z"></path>
              <path d="M74.23,72.41V93.26a1.17,1.17,0,1,0,2.34,0V72.41a1.17,1.17,0,1,0-2.34,0Z"></path>
              <path d="M18.1,59.5a1.15,1.15,0,0,0,1,.63,1.12,1.12,0,0,0,.53-.13L40.36,49.31a1.17,1.17,0,1,0-1.07-2.07L18.6,57.93a1.16,1.16,0,0,0-.5,1.57Z"></path>
              <path d="M58.28,45.12,82.68,60a1.17,1.17,0,1,0,1.2-2L59.48,43.12a1.17,1.17,0,1,0-1.2,2Z"></path>
            </svg>
          </div>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter tracking code"
              id="parcelInput"
              value={trackingCode}
              onChange={(e) => setTrackingCode(e.target.value)}
            />

            <button type="submit" id="trackButton">
              {isLoading ? <Loader /> : "Track Now"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default TrackParcel;
